import { Main } from '../../features/ui/main';
import { Form, Input, Button } from '../../features/ui/form';
import { useState } from 'react';
import styles from './styles.module.scss';
import { useI18n } from '../../features/i18n';
import Head from 'next/head';
import { useLogin, useUser } from '../../features/user';

type FormValues = {
  login: string;
  password: string;
};

export default function Login() {
  const user = useUser({
    redirectAuthedTo: '/',
  });
  const login = useLogin();
  // TODO: fix
  const [loginValidationErrors] = useState<string[]>([]);
  const { i18n } = useI18n();

  function onSubmit(values: FormValues) {
    login.mutate({
      email: values.login,
      password: values.password,
    });
  }

  return (
    <>
      <Head>
        <title>{i18n('loginPageTitle')}</title>
      </Head>
      {!user.data && (
        <Main className={styles['loginPage']}>
          <Form<FormValues>
            className={styles['loginPage__form']}
            onSubmit={onSubmit}
          >
            <Input
              validationError={loginValidationErrors}
              name={'login'}
              label={i18n('loginLabel')}
            />
            <Input
              name={'password'}
              label={i18n('passwordLabel')}
              type={'password'}
            />
            <Button block={true} loading={login.isLoading} type={'submit'}>
              {i18n('loginAction')}
            </Button>
          </Form>
        </Main>
      )}
    </>
  );
}
